import { useState, useEffect } from "react";
import { ReplaySubject } from "rxjs";

export const primaryNavHeightObs = new ReplaySubject(1);

export function recalculatePrimaryNavHeight() {
  primaryNavHeightObs.next(0);
}

export function usePrimaryNavHeight() {
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const height$ = primaryNavHeightObs.subscribe((newHeight) => {
      setHeight(newHeight);
    });

    return () => {
      height$.unsubscribe();
    };
  }, []);

  return height;
}
