import { useState, useEffect } from "react";
import { ReplaySubject } from "rxjs";

export const primaryNavWidthObs = new ReplaySubject(1);

export function recalculatePrimaryNavWidth() {
  primaryNavWidthObs.next(80);
}

export function usePrimaryNavWidth() {
  const [width, setWidth] = useState(80);
  useEffect(() => {
    const width$ = primaryNavWidthObs.subscribe(setWidth);

    return () => {
      width$.unsubscribe();
    };
  }, []);

  return width;
}
