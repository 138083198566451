import React, { useEffect } from "react";
import auth, { useWithUserAndTenant } from "cp-client-auth!sofe";
import { recalculatePrimaryNavWidth } from "./primary-nav-width.helpers.js";
import { ErrorBoundary } from "error-logging!sofe";
import { LoadPractitionerContainer } from "./load-practitioner-container.component.js";

export function RootComponent(props) {
  const [user, tenant] = useWithUserAndTenant();

  // keep track of user sessions and log them out when their sessions expire
  useEffect(() => auth.trackSession(), []);

  useEffect(() => {
    recalculatePrimaryNavWidth();
  }, [user, tenant]);

  if (user && tenant) {
    return <ClientOrPractitioner user={user} tenant={tenant} />;
  } else {
    return null;
  }
}

export function ClientOrPractitioner(props) {
  const { user, tenant } = props;

  if (user.role === "Client") {
    // This is already happening from app-loader-ui
    // this is simply done as insurance if for whatever reason
    // the user wasnt loaded when checkRootAppRedirect is called
    window.location = `${window.location.origin}/m/clients`;
    return <></>;
  } else {
    return <LoadPractitionerContainer {...props} />;
  }
}

export default ErrorBoundary({
  featureName: "Primary navbar",
  noStrictMode: true,
})(RootComponent);
