// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-navbar-styles__navbar--Zsmjq {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  z-index: 9999;
  background-color: #1f1e29;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px;
}

.primary-navbar-styles__navTop--Td8hR,
.primary-navbar-styles__navBottom--y7yvR {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.primary-navbar-styles__logo--rwGzt {
  height: 36px;
}

.primary-navbar-styles__globalAdd--xKN5m {
  transition: margin-top 200ms ease;
  margin-top: 68px;
}

.primary-navbar-styles__navItems--oSNsr {
  transition: margin-top 200ms ease;
  margin-top: 24px;
  width: 100%;
}

.primary-navbar-styles__navTools--ZYzY0 {
  margin-bottom: 24px;
  width: 100%;
}

@media (max-height: 824px) {
  .primary-navbar-styles__globalAdd--xKN5m {
    margin-top: 25px;
  }

  .primary-navbar-styles__navItems--oSNsr {
    margin-top: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/practitioner/primary-navbar.styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".navbar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100%;\n  width: 80px;\n  z-index: 9999;\n  background-color: #1f1e29;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding-top: 32px;\n}\n\n.navTop,\n.navBottom {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.logo {\n  height: 36px;\n}\n\n.globalAdd {\n  transition: margin-top 200ms ease;\n  margin-top: 68px;\n}\n\n.navItems {\n  transition: margin-top 200ms ease;\n  margin-top: 24px;\n  width: 100%;\n}\n\n.navTools {\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n@media (max-height: 824px) {\n  .globalAdd {\n    margin-top: 25px;\n  }\n\n  .navItems {\n    margin-top: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `primary-navbar-styles__navbar--Zsmjq`,
	"navTop": `primary-navbar-styles__navTop--Td8hR`,
	"navBottom": `primary-navbar-styles__navBottom--y7yvR`,
	"logo": `primary-navbar-styles__logo--rwGzt`,
	"globalAdd": `primary-navbar-styles__globalAdd--xKN5m`,
	"navItems": `primary-navbar-styles__navItems--oSNsr`,
	"navTools": `primary-navbar-styles__navTools--ZYzY0`
};
export default ___CSS_LOADER_EXPORT___;
