import React, { useState, useEffect } from "react";
import { primaryNavHeightObs } from "./primary-navbar.js";

const TopnavSecondary = ({ className = "", children, style = {} }) => {
  const [navTop, setNavTop] = useState(0);

  useEffect(() => {
    const subscription = primaryNavHeightObs.subscribe((height) => {
      setNavTop(height);
    });

    return () => subscription.unsubscribe();
  }, []);

  const mergedStyles = {
    ...style,
    top: String(navTop) + "px",
  };

  const newNavStyle = "+new";

  return (
    <div
      className={`${className} cps-topnav-secondary ${newNavStyle}`}
      style={mergedStyles}
      data-testid="topnav-secondary"
    >
      {children}
    </div>
  );
};

export default TopnavSecondary;
