import React, { Suspense, lazy } from "react";

const PractionerContainer = lazy(() =>
  import(
    /* webpackChunkName: "PractitionerContainer" */ "./practitioner/practitioner.container.js"
  )
);

export function LoadPractitionerContainer(props) {
  return (
    <Suspense fallback={null}>
      <PractionerContainer {...props} />
    </Suspense>
  );
}
